import React from "react";

class Page404 extends React.Component{
    render() {
        return(
            <div>
                <h1>error 404 . page not found</h1>
            </div>
        )
    }
}
export default Page404