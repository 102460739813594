import React, {useEffect, useState} from "react";
import {Card, CardActions, CardContent, CardMedia, CircularProgress, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {green} from "@mui/material/colors";
import ErrorMsg from "../msg/ErrorMsg";
import { Navigate } from 'react-router-dom';
import axios from "axios";

const Login = ()=>{
    const [loading , setLoading] = useState(false);
    const [username , setusername] = useState("");
    const [password , setPassword] = useState("");
    const [errorMsg , setErrorMsg] = useState("");
    const [isLogin , setIsLogin] = useState(localStorage.getItem("islogin"));

    const handleLoginClick = () =>{
        if (username !== '' && password !== ''){
            setLoading(true);
            const data = {
                username: username,
                password: password,
            };
            axios.post("https://pmlo.sinaser.cpanelpanel.xyz/api/login.php",data)
                .then(respone => {
                    if (respone.data.status !=="0")
                    {
                        localStorage.setItem("token",respone.data.token);
                        localStorage.setItem("islogin",true);
                        setIsLogin(true);
                    }
                    else
                    {
                        setErrorMsg(respone.data.message);
                        setLoading(false);
                    }
                })
                .catch(error =>{
                    if ( error.message === "Network Error"){
                        setLoading(false);
                        setErrorMsg("خطا در ارتباط با سرور");
                    }

                })

        }
        else
            setErrorMsg("نام کاربری یا رمز عبور نباید خالی باشد")
    }
    const handleUsernameChange = (event)=>{
        setusername(event.target.value);
    }
    const handlePasswordChange = (event) =>{
        setPassword(event.target.value);
    }
    const Redirect = ()=>{
        return (<Navigate replace to="/" />)
    }
    return(

        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
            {isLogin ? <Redirect/> : ''}
            {errorMsg !== '' ? <ErrorMsg message={errorMsg} openn={true} seterrormsg={setErrorMsg}/> : '' }
            <Card sx={{ maxWidth: 345 , margin:2}}>
                <CardMedia
                    component="img"
                    height="300"
                    image=""
                    alt="ALPHA MANAGMENT SYSTEM"
                    src="https://s2.uupload.ir/files/photo_6025912496260495857_x_kv7y.jpg"
                />
                <CardContent>
                    <Typography gutterBottom variant="h7" component="div" sx={{textAlign:"center",fontWeight:"bold"}}>
                        صفحه ورود
                    </Typography>
                    <TextField
                        autoComplete='off'
                        type='text'
                        fullWidth
                        id="outlined-basic"
                        label="نام کاربری"
                        size="small"
                        onChange={handleUsernameChange}
                        variant="outlined" sx={{
                        marginTop:2,
                        marginBottom:1,
                    }} />
                    <TextField
                        autoComplete='off'
                        type='password'
                        fullWidth
                        id="outlined-basic"
                        size="small"
                        label="رمز عبور"
                        onChange={handlePasswordChange}
                        variant="outlined" sx={{
                        marginTop:2,
                        marginBottom:1,
                    }} />
                </CardContent>
                <div style={{position: 'relative'}}>
                    <Button
                        fullWidth
                        onClick={handleLoginClick}
                        disabled={loading}
                        variant="contained">ورود</Button>
                    {loading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: green[500],
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                        />
                    )}
                </div>
            </Card>
        </div>

    )
}
export default Login
