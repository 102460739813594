import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import axios from "axios";
import {CircularProgress, Dialog} from "@mui/material";

const ShowSinglePrint=()=>{
    const params = useParams();
    const [factor , setFactor] = useState(params.factorkey);
    const [factorItems , setFactorItems] = useState(false);
    const [loading , setLoading] = useState(false);
    let fontsize = localStorage.getItem("fontsize");
    let fontname = localStorage.getItem("fontname");
    useEffect(()=>{
        setLoading(true);
        const data={
            id : factor,
            token : localStorage.getItem("token"),
        }
        axios.post("https://pmlo.sinaser.cpanelpanel.xyz/api/printform.php",data)
            .then(response=>{
                setFactorItems(response.data);
            })
            .catch(error=>{

        })
    },[])
    useEffect(()=>{
        if (factorItems !== false)
        {
            setLoading(false);
        }
    },[factorItems])
    return (
        <div style={{direction:"rtl",fontSize:`${fontsize}px`}} >
            <div className="font print">
                <div className={fontname}
                    style={{textAlign:"right",fontWeight:"bold"}}> نحوه ارسال : {factorItems.how_send} -   شماره فاکتور : {factorItems.facid} </div>
                {factorItems.sender !== "none" ?
                    <div className={fontname}
                        style={{textAlign:"right"}}> نام فرستنده : {factorItems.sender} </div> : ""}
                {factorItems.ostan !== "none" ?
                    <div className={fontname}
                        style={{textAlign:"right"}}> آدرس گیرنده : {factorItems.ostan} - {factorItems.city} </div> : ""}
                {factorItems.number !== "none" ?
                    <div className={fontname}
                        style={{textAlign:"right"}}> نام گیرنده : {factorItems.userid} - {factorItems.number} </div> : ""}
                {factorItems !== false ?
                    <div>
                        {factorItems.items.map(items=>(
                            <ul>
                                <li className={fontname}
                                    style={{textAlign:"right"}}>نام محصول : {items.name} , سایز : {items.size} , رنگ :  {items.color} ,  تعداد :  {items.count}</li>
                            </ul>
                        ))}
                    </div>

                    : '' }
                <div className={fontname}
                    style={{textAlign:"right",fontWeight:"bold"}}>از خرید و اعتماد شما سپاس گذاریم </div>
            </div>
            <Dialog
                open={loading}
                scroll="paper">
                <CircularProgress sx={{margin:2}} />
            </Dialog>
        </div>
    )
}
export default ShowSinglePrint;