import React,{useState,useEffect} from "react";
import {
    Box, CircularProgress, Dialog, Divider, MenuItem, Select, TextField
} from "@mui/material";
import Appbar from "./Appbar";
import Button from "@mui/material/Button";
import axios from "axios";
import ErrorMsg from "./msg/ErrorMsg";
import {Navigate} from "react-router-dom";

const Printform =()=>{
    const [isLogin , setIsLogin] = useState(localStorage.getItem("islogin"));
    const [totalRow , setTotalRow] = useState("");
    const [printType , setPrintType] = useState(1);
    const [printList , setPrintList] = useState(false);
    const [errorMsg , setErrorMsg] = useState("");
    const [loading , setLoading] = useState(false);
    const [update , setUpdate] = useState(false);
    let fontSize = localStorage.getItem("fontsize");
    let fontname = localStorage.getItem("fontname");
    useEffect(()=>{
        fontSize = localStorage.getItem("fontsize");
        fontname = localStorage.getItem("fontname");
        setUpdate(false);
    },[update])
    const handle_change_totalrow=(event)=>{
        setTotalRow(event.target.value);
    }
    const handleChangePrintType = (event) => {
        setPrintType(event.target.value);
    };
    const Redirect = ()=>{
        return (<Navigate replace to="/login" />)
    }
    const handle_btn_print = ()=>{
        setLoading(true);
        const data={
            token : localStorage.getItem("token"),
            total : totalRow,
            type : printType
        }
        axios.post("https://pmlo.sinaser.cpanelpanel.xyz/api/printform.php",data)
            .then(response=>{
                if (!response.data.error)
                {
                    console.log(response.data);
                    setPrintList(response.data);
                    setLoading(false);
                }
                else
                {
                    if (response.data.status === "0")
                    {
                        setErrorMsg(response.data.message);
                        localStorage.clear();
                        setIsLogin(false);
                    }
                }
            })
            .catch(error=>{
                setErrorMsg("خطا در ارتباط با سرور");
        })
    }
    return(
        <div >
            <Appbar update={setUpdate}  />
            <div style={{marginTop:"40px",direction:"rtl",width:"100%",margin:"auto",textAlign:"center"}}>
                {!isLogin ? <Redirect/> : ''}
                {errorMsg !== '' ? <ErrorMsg message={errorMsg} openn={true} seterrormsg={setErrorMsg}/> : '' }
                <Box
                    component="form"
                    className="no-print"
                    sx={{
                        '& > :not(style)': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off">
                    <TextField
                        className="fontfarsi"
                        autoComplete='off'
                        type='number'
                        id="outlined-basic"
                        size="small"
                        placeholder="تعداد چاپ"
                        onChange={handle_change_totalrow}
                        variant="outlined" sx={{
                        marginTop:2,
                        marginBottom:1,
                    }} />
                    <Select
                        className={"fontfarsi"}
                        labelId="demo-simple-select-label"
                        size="small"
                        value={printType}
                        onChange={handleChangePrintType}
                        id="demo-simple-select">
                        <MenuItem value={0}>چاپ نشده</MenuItem>
                        <MenuItem value={1}>چاپ شده</MenuItem>
                    </Select>
                        <Button
                            fullWidth
                            className="fontfarsi"
                            onClick={handle_btn_print}
                            variant="contained">
                            چاپ
                        </Button>
                </Box>
                {printList !== false ?
                <div>
                    {printList.map(item=>(
                        <div className="print"
                             style={{
                                 fontFamily: "tahoma !important",
                                 fontSize:`${fontSize}px`,
                                 textAlign:"right"}}>
                            <div className={fontname}
                                style={{textAlign:"right",fontWeight:"bold",lineHeight:"2em"}}> نحوه ارسال : {item.how_send} -   شماره فاکتور : {item.facid} </div>
                            {item.sender !== "none" ?
                                <div className={fontname}
                                    style={{textAlign:"right"}}> نام فرستنده : {item.sender} </div> : "" }
                            {item.ostan !== "none" ?
                                <div className={fontname}
                                    style={{textAlign:"right"}}> آدرس گیرنده : {item.ostan} - {item.city} </div> : ""}
                            {item.number !== "none" ?
                                <div className={fontname}
                                    style={{textAlign:"right",fontWeight:"bold"}}> نام گیرنده : {item.userid} - {item.number} </div> : ""}
                            {item.items.map(product=>(
                                <ul
                                    style={{textAlign:"right",paddingRight:"1px",listStyleType:"none"}}>
                                    <li className={fontname}
                                        style={{textAlign:"right",padding:"0",listStyleType:"none",fontWeight:"bold"}}>
                                        نام محصول : {product.name} , سایز : {product.size} , رنگ :  {product.color} ,  تعداد :  {product.count}
                                    </li>
                                </ul>
                            ))}
                            <p className={fontname}
                                style={{textAlign:"right",fontWeight:"bold"}}>از خرید و اعتماد شما سپاس گذاریم </p>
                            <Divider className="no-print" />
                        </div>
                    ))}

                </div>
                : '' }
                <Dialog
                    open={loading}
                    scroll="paper">
                    <CircularProgress sx={{margin:2}} />
                </Dialog>
            </div>
        </div>
    )
}
export default Printform;