import React,{useState,useEffect} from "react";
import Appbar from "./Appbar";
import axios from "axios";
import {Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, Grid, Paper, styled,
    Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow
} from "@mui/material";
import ErrorMsg from "./msg/ErrorMsg";
import {Link, Navigate} from "react-router-dom";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ClearIcon from '@mui/icons-material/Clear';
import Button from "@mui/material/Button";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const Printfactor =()=>{
    const [isLogin , setIsLogin] = useState(localStorage.getItem("islogin"));
    const [update , setUpdate] = useState(false);
    const [errorMsg , setErrorMsg] = useState("");
    const [factorList , setFactorList] = useState([]);
    const [dialogProduct , setDialogProduct] = useState(false);
    const [loadingPD , setLoadingPD] = useState(true);
    const [selectedRow , setSelectedRow] = useState(0);
    const [loadingFL , setLoadingFL] = useState(true)
    useEffect(()=>{
        const data={
            token : localStorage.getItem("token")
        };
        axios.post("https://pmlo.sinaser.cpanelpanel.xyz/api/get_factors.php",data)
            .then(respone => {
                if (!respone.data.error)
                {
                    setFactorList(respone.data);
                    setLoadingPD(false);
                }
                else
                {
                    if (respone.data.status === "0")
                    {
                        setErrorMsg(respone.data.message);
                        localStorage.clear();
                        setIsLogin(false);
                        setLoadingPD(false);
                    }
                }
            })
            .catch(error =>{
                if ( error.message === "Network Error"){
                    setErrorMsg("خطا در ارتباط با سرور");
                    setLoadingPD(false);
                }
            })
    },[]);

    useEffect(()=>{
        if (factorList.length > 0)
            setLoadingFL(false);
    },[factorList, factorList.length])
    const handle_print_single=()=>{
        setLoadingPD(true);
    }
    const Redirect = ()=>{
        return (<Navigate replace to="/login" />)
    }
    const handle_dialogproduct_click=(id)=>{
        setSelectedRow(id);
        setDialogProduct(true);
    }
    return(
        <div>
            {!isLogin ? <Redirect/> : ''}
            {errorMsg !== '' ? <ErrorMsg message={errorMsg} openn={true} seterrormsg={setErrorMsg}/> : '' }
            <Appbar/>

            <Box sx={{direction:"rtl",width:"85%",minWidth:"450px",margin:"auto",marginTop:3,textAlign:"center"}}>
                {!loadingFL ?
                <div>
                    <TableContainer component={Paper} >
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell className={"fontfarsi"} align="right">شماره فاکتور</StyledTableCell>
                                    <StyledTableCell className={"fontfarsi"} align="right">نام محصول</StyledTableCell>
                                    <StyledTableCell className={"fontfarsi"} align="right">رنگ</StyledTableCell>
                                    <StyledTableCell className={"fontfarsi"} align="right">سایز</StyledTableCell>
                                    <StyledTableCell className={"fontfarsi"} align="right">تعداد</StyledTableCell>
                                    <StyledTableCell className={"fontfarsi"} align="right">گیرنده</StyledTableCell>
                                    <StyledTableCell className={"fontfarsi"} align="right">چاپ</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {factorList.map((items,index) => (
                                    <StyledTableRow
                                        key={index}
                                        sx={{cursor:"pointer",textDecoration:"none"}}
                                        onClick={()=>{handle_dialogproduct_click(index)}}>
                                        <StyledTableCell align="right" component="th" scope="row" className={"fontfarsi"}>
                                            {items.facid}
                                        </StyledTableCell>
                                        <StyledTableCell align="right" className={"fontfarsi"}>
                                            {items.items[0].name}
                                        </StyledTableCell>
                                        <StyledTableCell align="right" className={"fontfarsi"}>
                                            {items.items[0].color}
                                        </StyledTableCell>
                                        <StyledTableCell align="right" className={"fontfarsi"}>
                                            {items.items[0].size}
                                        </StyledTableCell>
                                        <StyledTableCell align="right" className={"fontfarsi"}>
                                            {items.items[0].count}
                                        </StyledTableCell>
                                        <StyledTableCell align="right" className={"fontfarsi"}>
                                            {items.city}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {items.print === "1" ? <TaskAltIcon sx={{color:"#1e9811"}}/> : <ClearIcon sx={{color:'#fd0000'}}/>}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Dialog
                        open={dialogProduct}
                        onClose={()=>{setDialogProduct(false)}}
                        scroll="paper">
                        {!loadingPD ?
                            <div>
                                <DialogContent dividers={'paper'}>
                                    <DialogContentText
                                        tabIndex={-1}>
                                        <Box sx={{minWidth:"200px",width: '100%',color:"#000000",fontSize:"14px",direction:"rtl" }}>
                                            <div>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={5}>
                                                        <p className={"fontfarsi"}>محصول</p>
                                                    </Grid>
                                                    <Grid item xs={7}>
                                                        {factorList[selectedRow].items.map(items=>(
                                                            <p className={"fontfarsi"}>
                                                                {items.name} , سایز : {items.size} , رنگ :  {items.color} ,  تعداد :  {items.count}
                                                            </p>
                                                        ))}
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={5}>
                                                        <p className={"fontfarsi"}>نحوه ارسال</p>
                                                    </Grid>
                                                    <Grid item xs={7}>
                                                        <p className={"fontfarsi"}>{factorList[selectedRow].how_send}</p>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={5}>
                                                        <p className={"fontfarsi"}>آدرس</p>
                                                    </Grid>
                                                    <Grid item xs={7}>
                                                        <p className={"fontfarsi"}>
                                                            {factorList[selectedRow].ostan}  , {factorList[selectedRow].city}
                                                        </p>
                                                    </Grid>
                                                </Grid>
                                                {factorList[selectedRow].number !== "none" ?
                                                <Grid container spacing={2}>
                                                    <Grid item xs={5}>
                                                        <p className={"fontfarsi"}>شماره</p>
                                                    </Grid>
                                                    <Grid item xs={7}>
                                                        <p className={"fontfarsi"}>{factorList[selectedRow].number}</p>
                                                    </Grid>
                                                </Grid>
                                                    : "" }
                                                {factorList[selectedRow].sender !== "none" ?
                                                <Grid container spacing={2}>
                                                    <Grid item xs={5}>
                                                        <p className={"fontfarsi"}>ارسال کننده</p>
                                                    </Grid>
                                                    <Grid item xs={7}>
                                                        <p className={"fontfarsi"}>{factorList[selectedRow].sender}</p>
                                                    </Grid>
                                                </Grid>
                                                    : "" }
                                            </div>
                                        </Box>
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button className={"fontfarsi"} onClick={()=>{setDialogProduct(false)}}>بستن</Button>
                                    <Button
                                        className={"fontfarsi"}
                                        component={Link} to={`/singleprint/${factorList[selectedRow].id}/`}
                                        onClick={handle_print_single}>چاپ</Button>
                                </DialogActions>
                            </div>
                            : <CircularProgress sx={{margin:2}} />}
                    </Dialog>
                </div>
                : '' }
                <Dialog
                    open={loadingPD}
                    scroll="paper">
                    <CircularProgress sx={{margin:2}} />
                </Dialog>
            </Box>

        </div>
    )
}
export default Printfactor;
