import React, {useState, useEffect} from "react";
import {
    AppBar,
    Avatar,
    Box,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    Drawer, FormControl,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import {Navigate, useNavigate} from "react-router-dom";
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import BallotIcon from '@mui/icons-material/Ballot';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import KeyIcon from '@mui/icons-material/Key';
import axios from "axios";
import Button from "@mui/material/Button";
import ErrorMsg from "./msg/ErrorMsg";
import SuccsMsg from "./msg/SuccsMsg";
import font from "./fonts/font.css"
import SettingsIcon from '@mui/icons-material/Settings';

const Appbar = ({update}) => {
    const [menuRight, setMenuRight] = useState(false);
    const [user, setUser] = useState([]);
    const [isLogin, setIsLogin] = useState(localStorage.getItem("islogin"));
    const [dialogChangePass, setDialogChangePass] = useState(false);
    const [oldpassword, setOldpassword] = useState("");
    const [newpassword, setNewpassword] = useState("");
    const [renewpassword, setRenewpassword] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [loading, setLoading] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");
    const [dialogSettings, setDialogSettings] = useState(false);
    const [reload, setReload] = useState(false);
    let fontSize = localStorage.getItem("fontsize");
    let fontname = localStorage.getItem("fontname");
    useEffect(() => {
        const data = {
            token: localStorage.getItem("token")
        }
        axios.post("https://pmlo.sinaser.cpanelpanel.xyz/api/get_info.php", data)
            .then(response => {
                if (!response.data.error) {
                    setUser(response.data);
                } else {
                    if (response.data.status === "0") {
                        localStorage.clear();
                        setIsLogin(false);
                    }
                }
            })
    }, []);
    const handlebottomSheet = (index) => {
        if (index === 0) {
            navigate("/");
            setMenuRight(false);
        }
        if (index === 1) {
            navigate("/factors");
            setMenuRight(false);
        }
        if (index === 2) {
            navigate("/print");
            setMenuRight(false);
        }
        if (index === 3) {
            navigate("/createuser");
            setMenuRight(false);
        }
        if (index === 4) {
            navigate("/manageusers");
            setMenuRight(false);
        }
        if (index === 5) {
            localStorage.clear();
            navigate("/login");
        }
        if (index === 6) {
            setDialogSettings(true);
        }
    }
    const navigate = useNavigate();
    const Redirect = () => {
        return (<Navigate replace to="/login"/>)
    }
    const handle_btn_changepass = () => {
        if (user.username !== "") {
            setLoading(true);
            const data = {
                token: localStorage.getItem("token"),
                oldpass: oldpassword,
                newpass: newpassword,
                renewpass: renewpassword
            }
            axios.post("https://sinaser.ir/api/changepass.php", data)
                .then(respone => {
                    if (!respone.data.error) {
                        setLoading(false);
                        if (respone.data.message.length > 1)
                            setSuccessMsg(respone.data.message);
                    } else {
                        if (respone.data.status === "0") {
                            setErrorMsg(respone.data.message);
                            localStorage.clear();
                            setIsLogin(false);
                            setLoading(false);
                        } else {
                            setErrorMsg(respone.data.message);
                            setLoading(false);
                        }
                    }
                })
                .catch(error => {
                    if (error.message === "Network Error") {
                        setErrorMsg("خطا در ارتباط با سرور");
                        setLoading(false);
                    }

                })
        }
    }
    const list = () => (
            <Box
                sx={{textAlign: "center", width: "300px"}}
                role="presentation"
                onKeyDown={() => {
                    setMenuRight(false)
                }}>
                <AppBar
                    position="static"
                    className="no-print"
                    sx={{backgroundColor: "inherit"}}>
                    <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            color="default"
                            aria-label="menu"
                            onClick={() => {
                                setMenuRight(false)
                            }}
                            sx={{mr: 2}}>
                            <CloseIcon/>
                        </IconButton>
                        <div className="fontfarsi" style={{textAlign: "right", color: "black", width: "100%"}}>آلفا</div>
                    </Toolbar>
                </AppBar>

                <Avatar
                    alt="ALPHA"
                    src=""
                    sx={{width: 73, height: 73, margin: "auto", marginTop: 3}}
                />
                <h5 className="fontfarsi">
                    <IconButton
                        color="default"
                        onClick={() => {
                            setDialogChangePass(true)
                        }}
                        aria-label="settings" component="label">
                        <KeyIcon/>
                    </IconButton>
                    عزیز خوش آمدید {user.username}
                </h5>
                <Divider/>
                <List sx={{textAlign: "right"}} dir={"rtl"}>
                    <ListItem className="fontfarsi" disablePadding>
                        <ListItemButton
                            onClick={() => {
                                handlebottomSheet(0)
                            }}>
                            <ListItemIcon sx={{direction: "rtl"}}>
                                <HomeIcon/>
                            </ListItemIcon>
                            <div className="fontfarsi" style={{textAlign: "right"}}>پیشخوان</div>
                        </ListItemButton>
                    </ListItem>
                    <ListItem className="fontfarsi" disablePadding>
                        <ListItemButton
                            onClick={() => {
                                handlebottomSheet(1)
                            }}>
                            <ListItemIcon sx={{direction: "rtl"}}>
                                <BallotIcon/>
                            </ListItemIcon>
                            <div style={{textAlign: "right"}}>فاکتور ها</div>
                        </ListItemButton>
                    </ListItem>
                    <ListItem className="fontfarsi" disablePadding>
                        <ListItemButton
                            onClick={() => {
                                handlebottomSheet(2)
                            }}>
                            <ListItemIcon sx={{direction: "rtl"}}>
                                <LocalPrintshopIcon/>
                            </ListItemIcon>
                            <div style={{textAlign: "right"}}>چاپ گروهی</div>
                        </ListItemButton>
                    </ListItem>
                    {user.post === "admin" ?
                        <ListItem disablePadding>
                            <ListItemButton
                                onClick={() => {
                                    handlebottomSheet(3)
                                }}>
                                <ListItemIcon sx={{direction: "rtl"}}>
                                    <PersonAddIcon/>
                                </ListItemIcon>
                                <div style={{textAlign: "right"}}>ایجاد کاربر جدید</div>
                            </ListItemButton>
                        </ListItem>
                        : ""}
                    {user.post === "admin" ?
                        <ListItem className="fontfarsi" disablePadding>
                            <ListItemButton
                                onClick={() => {
                                    handlebottomSheet(4)
                                }}>
                                <ListItemIcon sx={{direction: "rtl"}}>
                                    <ManageAccountsIcon/>
                                </ListItemIcon>
                                <div style={{textAlign: "right"}}>مدیریت کاربران</div>
                            </ListItemButton>
                        </ListItem>
                        : ""}
                    <ListItem className="fontfarsi" disablePadding>
                        <ListItemButton
                            onClick={() => {
                                handlebottomSheet(6)
                            }}>
                            <ListItemIcon sx={{direction: "rtl"}}>
                                <SettingsIcon/>
                            </ListItemIcon>
                            <div style={{textAlign: "right"}}>تنظیمات</div>
                        </ListItemButton>
                    </ListItem>
                    <ListItem className="fontfarsi" disablePadding>
                        <ListItemButton
                            onClick={() => {
                                handlebottomSheet(5)
                            }}>
                            <ListItemIcon sx={{direction: "rtl"}}>
                                <ExitToAppIcon/>
                            </ListItemIcon>
                            <div style={{textAlign: "right"}}>خروج از سیستم</div>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
        )
    ;
    const handle_btn_changeFontsize = (fsize) => {
        localStorage.setItem("fontsize", fsize);
        setReload(true);
    }
    const handle_btn_changeFontName = (fname) => {
        localStorage.setItem("fontname", fname);
        setReload(true);
    }
    useEffect(() => {
        fontSize = localStorage.getItem("fontsize");
        fontname = localStorage.getItem("fontname");
        setReload(false);
    }, [reload])
    return (

        <Box sx={{flexGrow: 1}}>
            {!isLogin ? <Redirect/> : ''}
            {errorMsg !== '' ? <ErrorMsg message={errorMsg} openn={true} seterrormsg={setErrorMsg}/> : ''}
            {successMsg !== '' ? <SuccsMsg message={successMsg} openn={true} setSuccessMsg={setSuccessMsg}/> : ''}
            <AppBar position="static" dir="rtl" sx={{bgcolor: "#000000"}} className="no-print">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{mr: 2, direction: "rtl", marginRight: 0}}
                        onClick={() => {
                            setMenuRight(true)
                        }}>
                        <MenuIcon/>
                    </IconButton>
                    <Typography className="fontfarsi" variant="h6" sx={{flexGrow: 1, textAlign: "center"}}>
                        سامانه مدیریتی آلفا
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                anchor="right"
                open={menuRight}
                onClose={() => {
                    setMenuRight(false)
                }}>
                {list()}
            </Drawer>
            <Dialog
                open={dialogChangePass}
                onClose={() => {
                    setDialogChangePass(false)
                }}
                scroll="paper">
                <div className="fontfarsi">
                    <DialogContent dividers={'paper'}>
                        <DialogContentText
                            id="scroll-dialog-description"
                            tabIndex={-1}>
                            <Box sx={{
                                minWidth: "250px",
                                width: '100%',
                                color: "#000000",
                                fontSize: "14px",
                                direction: "rtl"
                            }}>
                                <p>کاربر : {user.username}</p>
                                <TextField
                                    fullWidth
                                    type="password"
                                    placeholder="رمز قبلی"
                                    className="fontfarsi"
                                    onChange={(event) => setOldpassword(event.target.value)}
                                    id="fullWidth"/>
                                <TextField
                                    fullWidth
                                    type="password"
                                    placeholder="رمز جدید"
                                    className="fontfarsi"
                                    sx={{marginTop: 2}}
                                    onChange={(event) => setNewpassword(event.target.value)}
                                    id="fullWidth"/>
                                <TextField
                                    fullWidth
                                    type="password"
                                    className="fontfarsi"
                                    placeholder="تکرار رمز"
                                    sx={{marginTop: 2}}
                                    onChange={(event) => setRenewpassword(event.target.value)}
                                    id="fullWidth"/>
                            </Box>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>

                        <Button className="fontfarsi" onClick={() => {
                            setDialogChangePass(false)
                        }}>بستن</Button>
                        <Button className="fontfarsi" onClick={handle_btn_changepass}>تغییر رمز</Button>
                    </DialogActions>
                </div>
            </Dialog>
            <Dialog
                open={dialogSettings}
                onClose={() => {
                    setDialogSettings(false)
                }}
                scroll="paper">
                <div className="fontfarsi">
                    <DialogContent dividers={'paper'}>
                        <DialogContentText
                            id="scroll-dialog-description"
                            tabIndex={-1}>
                            <Box
                                className={"fontfarsi"}
                                sx={{
                                    minWidth: "250px",
                                    width: '100%',
                                    color: "#000000",
                                    fontSize: "14px",
                                    direction: "rtl"
                                }}>
                                <p className={"fontfarsi"}>فونت چاپ</p>
                                <FormControl fullWidth>
                                    <Select
                                        onChange={(e) => handle_btn_changeFontName(e.target.value)}
                                    >
                                        <MenuItem value={"fontfarsi"}>Iransans</MenuItem>
                                        <MenuItem value={"fonttahoma"}>Tahoma</MenuItem>
                                        <MenuItem value={"fontarial"}>Arial</MenuItem>
                                    </Select>
                                </FormControl>
                                <p className={"fontfarsi"}>سایز فونت چاپ</p>
                                <FormControl fullWidth>
                                    <Select
                                        onChange={(e) => handle_btn_changeFontsize(e.target.value)}
                                    >
                                        <MenuItem value={9}>9</MenuItem>
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={11}>11</MenuItem>
                                        <MenuItem value={12}>12</MenuItem>
                                        <MenuItem value={13}>13</MenuItem>
                                        <MenuItem value={14}>14</MenuItem>
                                        <MenuItem value={15}>15</MenuItem>
                                        <MenuItem value={16}>16</MenuItem>
                                        <MenuItem value={17}>17</MenuItem>
                                        <MenuItem value={18}>18</MenuItem>
                                    </Select>
                                </FormControl>
                                <Typography
                                    className={fontname}
                                    sx={{
                                        marginTop: 2,
                                        fontSize: `${fontSize}px`,
                                    }}>متن تست برای نمایش فونت</Typography>
                            </Box>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button className="fontfarsi" onClick={() => {
                            setDialogSettings(false)
                        }}>بستن</Button>
                        <Button
                            className="fontfarsi"
                            onClick={() => {
                                setDialogSettings(false);
                                update(true);
                            }}
                        >
                            ثبت تغییرات
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
            <Dialog
                open={loading}
                scroll="paper">
                <CircularProgress sx={{margin: 2}}/>
            </Dialog>
        </Box>
    )
}
export default Appbar;
