import React,{useState} from "react"
import Appbar from "./Appbar";
import {Box, CircularProgress, Dialog, Divider, MenuItem, Select, Stack, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import axios from "axios";
import ErrorMsg from "./msg/ErrorMsg";
import {Navigate} from "react-router-dom";
import SuccsMsg from "./msg/SuccsMsg";

const CreateUser =()=>{
    const [isLogin , setIsLogin] = useState(localStorage.getItem("islogin"));
    const [userName , setUserName] = useState("");
    const [password , setPassword] = useState("");
    const [post , setPost] = useState("user");
    const [dbName , setDbName] = useState("");
    const [dbUserName , setDbUserName] = useState("");
    const [dbPassword , setDbPassword] = useState("");
    const [loading , setLoading] = useState(false);
    const [errorMsg , setErrorMsg] = useState("");
    const [succssMsg , setSuccssMsg] = useState("");

    const handle_change_username =(event)=>{
        setUserName(event.target.value);
    }
    const handle_change_password =(event)=>{
        setPassword(event.target.value);
    }
    const handle_change_post = (event)=>{
        setPost(event.target.value);
    }
    const handle_change_dbname =(event)=>{
        setDbName(event.target.value);
    }
    const handle_change_dbusername=(event)=>{
        setDbUserName(event.target.value);
    }
    const handle_change_dbpassword=(event)=>{
        setDbPassword(event.target.value);
    }
    const Redirect = ()=>{
        return (<Navigate replace to="/login" />)
    }
    const handle_btn_createuser = ()=>{
        setLoading(true);
        const data={
            username : userName,
            password : password,
            post : post,
            dbname : dbName,
            dbpassword : dbPassword,
            dbusername : dbUserName,
            token : localStorage.getItem("token")
        }
        axios.post("https://pmlo.sinaser.cpanelpanel.xyz/api/addnew_user.php",data)
            .then(response=>{
                console.log(response.data);
                if (response.data.error === true)
                {
                    if (response.data.status === "1")
                        setErrorMsg(response.data.message);
                }
                else
                {
                    setSuccssMsg(response.data.message);
                }

                setLoading(false);
            })
            .catch(error=>{
                setErrorMsg("خطا ! با پشتیبانی تماس بگیرید");
            })
    }
    return(
        <div>
            <Appbar/>
            {!isLogin ? <Redirect/> : ''}
            {errorMsg !== '' ? <ErrorMsg message={errorMsg} openn={true} seterrormsg={setErrorMsg}/> : '' }
            {succssMsg !== '' ? <SuccsMsg message={succssMsg} openn={true} seterrormsg={setSuccssMsg}/> : '' }
            <Box sx={{width:"40%",margin:"auto",direction:"rtl",minWidth:"300px"}}>
                <Stack spacing={2} sx={{marginTop:3,marginBottom:4}}>
                    <div>
                        <Typography sx={{fontSize:"13px",color:"#6b6b6b",marginBottom:1}}>نام کاربری</Typography>
                        <TextField
                            autoComplete='off'
                            type='text'
                            id="outlined-basic"
                            size="small"
                            placeholder="نام کاربری"
                            fullWidth
                            onChange={handle_change_username}
                            variant="outlined" />
                    </div>
                    <div>
                        <Typography sx={{fontSize:"13px",color:"#6b6b6b",marginBottom:1}}>رمز عبور</Typography>
                        <TextField
                            autoComplete='off'
                            type='text'
                            id="outlined-basic"
                            size="small"
                            placeholder="رمز عبور"
                            fullWidth
                            onChange={handle_change_password}
                            variant="outlined" />
                    </div>
                    <div>
                        <Typography sx={{fontSize:"13px",color:"#6b6b6b",marginBottom:1}}>سمت</Typography>
                        <Select
                            labelId="demo-simple-select-label"
                            size="small"
                            value={post}
                            fullWidth
                            onChange={handle_change_post}
                            id="demo-simple-select">
                            <MenuItem value="admin">مدیر</MenuItem>
                            <MenuItem value="user">کاربر</MenuItem>
                        </Select>
                    </div>
                    <Typography sx={{textAlign:"center",fontSize:"13px",color:"#6b6b6b",marginBottom:1}}>اطلاعات مربوط به دیتابیس</Typography>
                    <Divider light />
                    <div>
                        <Typography sx={{fontSize:"13px",color:"#6b6b6b",marginBottom:1}}>نام دیتابیس</Typography>
                        <TextField
                            autoComplete='off'
                            type='text'
                            id="outlined-basic"
                            size="small"
                            fullWidth
                            onChange={handle_change_dbname}
                            variant="outlined" />
                    </div>
                    <div>
                        <Typography sx={{fontSize:"13px",color:"#6b6b6b",marginBottom:1}}>نام کاربری دیتابیس</Typography>
                        <TextField
                            autoComplete='off'
                            type='text'
                            id="outlined-basic"
                            size="small"
                            fullWidth
                            onChange={handle_change_dbusername}
                            variant="outlined" />
                    </div>
                    <div>
                        <Typography sx={{fontSize:"13px",color:"#6b6b6b",marginBottom:1}}>رمزعبور دیتابیس</Typography>
                        <TextField
                            autoComplete='off'
                            type='text'
                            id="outlined-basic"
                            size="small"
                            fullWidth
                            onChange={handle_change_dbpassword}
                            variant="outlined" />
                    </div>
                    <Button
                        fullWidth
                        onClick={handle_btn_createuser}
                        variant="contained">
                        ایجاد کاربر جدید
                    </Button>
            </Stack>
                <Dialog
                    open={loading}
                    scroll="paper">
                    <CircularProgress sx={{margin:2}} />
                </Dialog>
            </Box>
        </div>
    )
}
export default CreateUser;