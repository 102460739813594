import logo from './logo.svg';
import './App.css';
import "./fonts/font.css"
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Dashboard from "./Dashboard";
import Page404 from "./page404";
import Login from "./auth/Login";
import Printfactor from "./Printfactor";
import Printform from "./Printform";
import CreateUser from "./CreateUser";
import ShowSinglePrint from "./ShowSinglePrint";
import ManageUser from "./ManageUser";
import {useState} from "react";

function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route>
            <Route path="/" element={<Dashboard/>} />
            {<Route index path="createuser" element={<CreateUser/>} />}
            {<Route index path="manageusers" element={<ManageUser/>} />}
            {<Route index path="singleprint/:factorkey" element={<ShowSinglePrint/>} />}
            {<Route index path="print" element={<Printform />} />}
            {<Route index path="factors" element={<Printfactor/>} />}
            {<Route path="login" element={<Login/>} />}
            {<Route path="*" element={<Page404/>} />}
          </Route>
        </Routes>
      </BrowserRouter>
  );
}

export default App;
