import React, {useEffect, useState} from "react";
import Appbar from "./Appbar";
import {Navigate} from "react-router-dom";
import axios from "axios";
import ErrorMsg from "./msg/ErrorMsg";
const Dashboard = ()=>{
    const [isLogin , setIsLogin] = useState(localStorage.getItem("islogin"));
    const [update , setUpdate] = useState(false);
    const [errorMsg , setErrorMsg] = useState("");

    useEffect(()=>{
        const data={
            token:localStorage.getItem("token")
        }
        axios.post("https://pmlo.sinaser.cpanelpanel.xyz/api/get_info.php",data)
            .then(response=>{
                if (!response.data.error)
                {

                }
                else
                {
                    if (response.data.status === "0")
                    {
                        localStorage.clear();
                        setIsLogin(false);
                    }
                }
            })
    },[]);
    const Redirect = ()=>{
        return (<Navigate replace to="/login" />)
    }
    return (
        <div className={"fontfarsi"}>
            {!isLogin ? <Redirect/> : ''}
            {errorMsg !== '' ? <ErrorMsg message={errorMsg} openn={true} seterrormsg={setErrorMsg}/> : '' }
            <Appbar/>
        </div>
    )
}
export default Dashboard;