import React,{useEffect,useState} from "react";
import {
    Box,
    CircularProgress,
    Dialog, DialogActions,
    DialogContent, DialogContentText,
    Paper,
    styled,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow, TextField
} from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";
import {Navigate} from "react-router-dom";
import ErrorMsg from "./msg/ErrorMsg";
import Appbar from "./Appbar";
import Button from "@mui/material/Button";
import SuccsMsg from "./msg/SuccsMsg";
import SettingsIcon from '@mui/icons-material/Settings';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const ManageUser = ()=>{
    const [isLogin , setIsLogin] = useState(localStorage.getItem("islogin"));
    const [userList , setUserList] = useState([]);
    const [errorMsg , setErrorMsg] = useState("");
    const [loading , setLoading] = useState(true);
    const [successMsg , setSuccessMsg] = useState("");
    const [dialogChangepass , setDialogChangepass] = useState(false);
    const [newpassword , setNewpassword] = useState("");
    const [renewpassword , setRenewpassword] = useState("");
    const [selectedUser , setSelectedUser] = useState("");


    useEffect(()=>{
        const data={
            token : localStorage.getItem("token")
        };

        axios.post("https://pmlo.sinaser.cpanelpanel.xyz/api/get_alluser.php",data)
            .then(respone => {
                if (!respone.data.error)
                {
                    setUserList(respone.data);
                    setLoading(false);
                    if (respone.data.message.length > 1)
                        setSuccessMsg(respone.data.message);
                    console.log(respone.data.message);
                }
                else
                {
                    if (respone.data.status === "0")
                    {
                        setErrorMsg(respone.data.message);
                        localStorage.clear();
                        setIsLogin(false);
                        setLoading(false)
                    }
                }
            })
            .catch(error =>{
                if ( error.message === "Network Error"){
                    setErrorMsg("خطا در ارتباط با سرور");
                    setLoading(false);
                }

            })
    },[]);
    const Redirect = ()=> {
        return (<Navigate replace to="/login" />)
    }
    const handle_btn_changepass = ()=>{
        const data={
            token : localStorage.getItem("token"),
            username: selectedUser,
            newpass : newpassword,
            renewpass : renewpassword
        }
        axios.post("https://pmlo.sinaser.cpanelpanel.xyz/api/changepass.php",data)
            .then(respone => {
                if (!respone.data.error)
                {
                    setLoading(false);
                    if (respone.data.message.length > 1)
                        setSuccessMsg(respone.data.message);
                }
                else
                {
                    if (respone.data.status === "0")
                    {
                        setErrorMsg(respone.data.message);
                        localStorage.clear();
                        setIsLogin(false);
                        setLoading(false)
                    }
                    else
                    {
                        setErrorMsg(respone.data.message);
                    }
                }
            })
            .catch(error =>{
                if ( error.message === "Network Error"){
                    setErrorMsg("خطا در ارتباط با سرور");
                    setLoading(false);
                }

            })

    }
    const handle_btn_dialogchangepass=(username)=>{
        setSelectedUser(username);
        setDialogChangepass(true);
    }
    const handle_btn_delete=(username)=>{

    }
    return(
        <div >
            <Appbar/>
            {!isLogin ? <Redirect/> : ''}
            {errorMsg !== '' ? <ErrorMsg message={errorMsg} openn={true} seterrormsg={setErrorMsg}/> : '' }
            {successMsg !== '' ? <SuccsMsg message={successMsg} openn={true} setSuccessMsg={setSuccessMsg}/> : '' }
            <div style={{width:"95%",direction:"rtl",margin:"auto",marginTop:30}}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell className={"fontfarsi"} align="right">کد</StyledTableCell>
                                <StyledTableCell className={"fontfarsi"} align="right">نام کاربری</StyledTableCell>
                                <StyledTableCell className={"fontfarsi"} align="right">سمت</StyledTableCell>
                                <StyledTableCell className={"fontfarsi"} align="right">نام دیتابیس</StyledTableCell>
                                <StyledTableCell className={"fontfarsi"} align="right">عملیات</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {userList.map((items,index) => (
                                <StyledTableRow key={index}>
                                    <StyledTableCell align="right" component="th" scope="row">
                                        {items.id}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">{items.username}</StyledTableCell>
                                    <StyledTableCell align="right">{items.post}</StyledTableCell>
                                    <StyledTableCell align="right">{items.dbname}</StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Button
                                            onClick={()=>{handle_btn_dialogchangepass(items.username)}}
                                            variant="outlined">
                                            <SettingsIcon/>
                                        </Button>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <Dialog
                open={loading}
                scroll="paper">
                <CircularProgress sx={{margin:2}} />
            </Dialog>
            <Dialog
                open={dialogChangepass}
                onClose={()=>{setDialogChangepass(false)}}
                scroll="paper">
                    <div>
                        <DialogContent dividers={'paper'}>
                            <DialogContentText
                                id="scroll-dialog-description"
                                tabIndex={-1}>
                                <Box sx={{minWidth:"250px",width: '100%',color:"#000000",fontSize:"14px",direction:"rtl" }}>
                                    <p>کاربر : {selectedUser} <Button color="error" variant="outlined" onClick={handle_btn_delete}>حذف کاربر</Button></p>
                                    <TextField
                                        fullWidth
                                        placeholder="رمز جدید"
                                        type="password"
                                        onChange={(event)=>setNewpassword(event.target.value)}
                                        id="fullWidth" />
                                    <TextField
                                        fullWidth
                                        placeholder="تکرار رمز"
                                        type="password"
                                        sx={{marginTop:2}}
                                        onChange={(event)=>setRenewpassword(event.target.value)}
                                        id="fullWidth" />
                                </Box>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={()=>{setDialogChangepass(false)}}>بستن</Button>
                            <Button onClick={handle_btn_changepass}>تغییر رمز</Button>
                        </DialogActions>
                    </div>
            </Dialog>
        </div>
    )
}
export default ManageUser;